import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.css']
})
export class AddonsComponent implements OnInit {
  productList: any = [];
  defaultImage = 'assets/images/loader.gif';
  loading = false;
  loadingBtn = false;
  showPopup = false;
  stockBox = false;
  modalBoxName = '';
  keyword = 'name';
  regionName:any = 0;
  searchedKeyword:string;
  addonsList:any = [];
  tax_id: any = 0;
  sno = 1;
  searchField: FormControl = new FormControl();
  errorMsg: any = [];
  taxList: any = [];
  viewBox = false;
  showImage = true;
  imageUrl = '';
  product_name;
  price;
  editproduct_name;
  editprice;
  addonsId;
  hsn:any = "";
  regionList: any = [];
  regionArray: any = [];
  region_id: any = [];
  editRegionId: any;
  edithsn:any = "";
  edittax_id:any = "";
  defaultImagetwo = 'assets/images/no_img.png';
  selectedRegions: any[] = [];
  editregionArray: string;

  constructor(private apiService: ApiService, private toastr: ToastrService, private imageService: ImageService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getAddonsListnotactice();
    this.index();
    
  }
  onItemSelect(item:any){
    console.log(item);
    console.log(this.regionArray);
}
  getAddonsListnotactice() {
    this.loading = true;
    this.apiService.getData('getAddonsListnotactice').subscribe(data => {
        this.addonsList = data.data;
    });
  }

  //  listAllRegions(){
  //   this.loading = true;
  //   this.apiService.getData('getAllActiveRegionList').subscribe((data) => {
  //       this.regionList = data.data;
  //       this.loading = false;
  //   }, error => {
  //       this.loading = false;
  //   });
  //  }

  listAllRegions() {
    this.loading = true;
    this.apiService.getData('getAllActiveRegionList').subscribe((data) => {
      const regions = data.data;
      for (const region of regions) {
        this.regionList.push({ id: region.region_id, itemName: region.city.name}); // Assuming 'address' property contains the region name
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  // openModalBox(type, id = '', name = '', price='', region_id='', hsn='', tax='') {
  //   this.listAllTax();
  //   this.listAllRegions();
  //   if(type === 'Edit') {
  //       this.modalBoxName = type;
  //       this.editproduct_name = name;
  //       this.editRegionId = region_id;
  //       this.editprice = price;
  //       this.addonsId = id;
  //       this.edithsn = hsn;
  //       this.edittax_id = tax;

  //       this.showPopup = true;
  //   } else if (type === 'Create') {
  //       this.modalBoxName = 'Create';
  //       this.regionName = 0;
  //       this.hsn ="";
  //       this.tax_id =0;

  //       this.showPopup = true;
  //   }
  // }

  openModalBox(type, id = '', name = '', price = '', region_id = '', hsn = '', tax = '') {
    this.listAllTax();
    this.listAllRegions();
    this.selectedRegions = [];

    if (type === 'Edit') {
      this.modalBoxName = type;
      this.show(id, name, price, region_id, hsn, tax);
      this.showPopup = true;
    } else if (type === 'Create') {
      this.modalBoxName = 'Create';
      this.regionName = 0;
      this.hsn = '';
      this.tax_id = 0;
      this.showPopup = true;
    }
  }

  show(id: string, name: string, price: string, region_id: string, hsn: string, tax: string) {
    this.apiService.show('createAddons/' + id).subscribe((data) => {
      if (data.error === false) {
        const value = data.data;

        this.editproduct_name = value.product_name;
        this.editprice = value.price;
        this.regionArray = value.regions;
        
        this.regionArray = value.regions.map((region:any) => ({     id: region.region_id,     itemName: region.city.name }));

        // for (const region of this.regionArray) {
        //   if (region.city) {
        //     this.regionList.push({ id: region.region_id, itemName: region.city.name });
        //     console.log('region11', this.regionList);
        //   }
        // }
        this.addonsId = value.addon_id;
        console.log("vaid",this.addonsId)
        this.edithsn = value.hsn;
        this.edittax_id = value.tax_id;
      } else {
        this.toastr.error(data.message);
      }
    });
  }
  popUpClose() {
    this.showPopup =  false;
    this.product_name = '';
    this.price = '';
    this.regionArray='';
    this.hsn='';
    // this.(keyup.enter)="openSize($event)";
    this.tax_id='';
}


  // createAddons(){
  //   if(this.tax_id == 0 ){
  //     this.toastr.warning('Please Enter Tax Percentage');
  //     return false;
  //   }
  //   this.loading = true;

  //   const param = {
  //     product_name: this.product_name,
  //     price: this.price,
  //     hsn: this.hsn,
  //     tax_id: this.tax_id,
  //     region_id : this.regionId
  //   }

  //   this.apiService.postData(param, 'createAddons').subscribe((data) => {
  //     if (data.error === false) {
  //         this.toastr.success(data.message);
  //         this.getAddonsListnotactice();
  //         this.popUpClose();
  //         this.loading = false;
  //     } else {
  //         this.toastr.warning(data.message);
  //         this.loading = false;
  //     }
  //   }, error => {
  //       this.loading = false;
  //   });
  // }

  store() {
    if(this.product_name == 0 ){
      this.toastr.warning('Please Select product_name ');
      return false;
    }else if(this.price == 0){
      this.toastr.warning('Please enter Product Price');
      return false;
    }else if(this.hsn == 0){
      this.toastr.warning('Please enter HSN Code');
      return false;
    }else if(this.tax_id == 0){
      this.toastr.warning('Please enter tax_id');
      return false;
    }else if(this.regionArray == 0){
      this.toastr.warning('Please Enter Region');
      return false;
    }
   else{
      this.loadingBtn = true;
      this.region_id = [];
      console.log(this.regionArray);
      this.regionArray.forEach(element => {
        this.region_id.push(element.id);
      });

     this.region_id.toString();
     this.apiCall('store', 'createAddons');
    }
}

  updateAddons(id:any){
  //   if(this.tax_id == 0 ){
  //     this.toastr.warning('Please Enter Tax Percentage');
  //     return false;
  //  }
    this.loading = true;

    const param = {
      addon_id:this.addonsId,
      product_name: this.editproduct_name,
      price: this.editprice,
      // addon_id: id,
      hsn: this.edithsn,
      tax_id: this.edittax_id,
      region_id : this.regionArray.map((item: any) => item.id),
      // categories_id: this.selectedItems.map((item: any) => item.id),
    }

    this.apiService.postData(param, 'updateAddons').subscribe((data) => {
      if (data.error === false) {
          this.toastr.success(data.message);
          this.getAddonsListnotactice();
          this.popUpClose();
          this.loading = false;
      } else {
          this.toastr.warning(data.message);
          this.loading = false;
      }
    }, error => {
        this.loading = false;
    });
  }

  apiCall(name, url, value: any = '') {
    value = name === 'store' || name === 'update' ? {
      region_id: this.region_id,
        product_name: this.product_name,
        price: this.price,
        hsn: this.hsn,
        tax_id: this.tax_id,
    } : value;
    this.apiService[name](url, value).subscribe((data) => {
        if (data.error === false) {
            this.toastr.success(data.message);
            this.loadingBtn = false;
            this.popUpClose();
            this.index();
        } else {
            this.toastr.error(data.message);
            this.loadingBtn = false;
            this.errorMsg = data.data;
        }
    });
  }


  index() {
    this.loading = true;
    this.apiService.index('indexAddons').subscribe(data => {
        this.addonsList = data.data;
        this.loading = false;
    });
}

  listAllTax(){
    this.loading = true;
    this.apiService.getData('getAllActiveTaxList').subscribe((data) => {
        this.taxList = data.data;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }



   addonSwitch(id) {
    this.apiCall('getData', 'addonSwitch/' + id);
}

clientsSwitch(id:any) {
  this.loading = true;
  this.apiService.getData('addonsSwitch/'+ id).subscribe(data => {
  if (data.error === false) {
      this.toastr.success(data.message);
      this.loadingBtn = false;
      this.popUpClose();
      this.index();
  } else {
      this.toastr.error(data.message);
      this.loadingBtn = false;
      this.errorMsg = data.data;
  }
      this.loading = false;
});
}



   onChange(event){
    this.region_id = event;
    this.edithsn = event;
    this.editRegionId = event;
   }
  deleteAddons(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this Addons file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
      }).then((result) => {
      if (result.value) {
          this.apiService.getData('deleteAddons', id).subscribe(data => {
          if (data.error === false) {
              this.toastr.success(data.message);
              this.sno = 1;
          } else {
              this.toastr.error(data.message);
          }
          this.getAddonsListnotactice();
          });
          Swal.fire(
          'Deleted!',
          'Your Addons file has been deleted.',
          'success'
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
          'Cancelled',
          'Your Addons file is safe :)',
          'error'
          );
      }
      });
  }
  viewImage(productId){

  }
  onChangeTax(event){
this.tax_id = event;
  }

}
