import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageService } from 'src/app/services/image.service';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import * as RecordRTC from 'recordrtc';
import Pusher from 'pusher-js';
import { Howl } from 'howler';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-orderbackup-details',
  templateUrl: './orderbackup-details.component.html',
  styleUrls: ['./orderbackup-details.component.css']
})
export class OrderbackupDetailsComponent implements OnInit {

    //@ViewChild('audioOption') audioPlayerRef: ElementRef;
    public counts = ["Submitted","Confirmed","Preparing", "Out For Delivery","Delivered"];
    private album: any = [];
    private orderImage:any = [];
    private addonImage:any = [];
    private driverUploadImage:any = [];
    searchField: FormControl = new FormControl();
    orderDetails: any = [];
    p = 1;
    imageURL;
    trackId = '';
    loading: boolean;
    cityName;
    regionId;
    pincodeList: any;
    locationDetailsId = 0;
    orderId;
    driverId = 0;
    orderStatus: any;
    pushToDispatch: any;
    dispatchAcceptance: any;
    driver_accepted_at: any;
    dispatchAcceptedAt:any;
    picked_up:any;
    picked_up_at:any;
    orderDeliveredAt:any;
    push_to_driver_id:any;
    push_to_driver_success:any;
    dispatchImagesProductId:any;
    admin_approved_at:any;
    dispatchAcceptancee: any;
    adminAcceptancee: any;
    driver_acceptancee:any;
    getDispatchOrderId:any= [];
    getDriverOrderId:any = [];
    orderStatusName:any='';
    dispatch_order_id;
    dispatchImagesList:any = [];
    cakeDoneImages;
    onlineTeamAccept;
    driverList:any = [];
    driver_order_id;
    dispatchOrderStatus:any;
    audiolaunch:any =[];
    viewComment: boolean;
    comment: string;
    showDatePicker: boolean = false;
    scheduleToDispatch: any = false;
    order_status:any= "Submitted";
    title = 'voice record';
    record:any="";
    recording = false;
    url:any="";
    str:any="";
    error:any="";
    voicenotes:any="";
    getvoice:any="";
    rejectionComment: string;
    addonDetails: any = [];
    shopList: any;
    shopId: any = 0;
    deliveryMode: any;
    getShopOrderId: any;
    viewSchedule: boolean;
    scheduledDate: any;
    pushed_to_driver_at: any;
    shop_accepted_at: any;
    shop_created_at: any;
    dispatchedOrderLength: any;
    cityId: any;
    driverDeliveredAt: any;
    intervalId: any;
    viewDrivers:boolean ;
    skipShop:boolean = false;
    customerDetails:any = [];
    pusher = new Pusher('8d0ad959b5759a0aa3ca', {
        cluster: 'ap2'
    });

    constructor(private apiService: ApiService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private imageService: ImageService,
        private _lightboxConfig: LightboxConfig,
        private _lightbox: Lightbox,
        private router: Router
    )
    {
        _lightboxConfig.showZoom = true;
        _lightboxConfig.showDownloadButton = true;
    }

    ngOnInit() {
        this.getOrderDetails();
        this.imageURL = this.imageService.getMinImageurl();
        var channel = this.pusher.subscribe('blaack-forest');
        channel.bind('admin', (data) => {
            if(this.router.url == '/order-detail/'+this.orderId){
                this.getOrderDetails();
            }
        });
    }


    getOrderDetails() {
        this.loading = true;
        this.apiService.getData('getOrderBackupDetails', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
        this.orderDetails = data.data;
        this.customerDetails = data.data.customer_details;
        this.addonDetails = data.data.ordered_addons;
        this.cityName = data.data.region.city.name;
        this.cityId = data.data.region.city_id;
        this.regionId = data.data.region_id;
        this.orderId = data.data.order_id;
        this.orderStatus= data.data.order_status;
        if(data.data.message == 'Failed'){
          this.orderStatusName = data.data.message;
        } else if(data.data.message == 'Success'){
          this.orderStatusName = data.data.message;
          this.goBack();
        }
        else{
          this.orderStatusName = 'Failed';
        }
        this.deliveryMode = data.data.delivery_mode;
        this.orderDeliveredAt= data.data.order_delivered_at;
        this.orderDetails.ordered_products.forEach(element => {
            const src = element.product_details[0].images[0].product_image;
            const caption = '';
            const thumb = element.product_details[0].images[0].product_image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this.orderImage.push(album)
        });
        this.addonDetails.forEach(element => {
            const src = element.image;
            const caption = '';
            const thumb = element.image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this.addonImage.push(album)
        });
        if(data.data.dispatch_orders){
            this.cakeDoneImages = data.data.dispatch_orders.cake_done_image;
            this.onlineTeamAccept = data.data.dispatch_orders.online_team_accept;
            this.locationDetailsId = data.data.dispatch_orders.location_details_id;
            this.dispatchAcceptancee = data.data.dispatch_orders.dispatch_order_status;
            this.dispatchAcceptedAt=data.data.dispatch_orders.dispatch_accepted_at;
            this.adminAcceptancee = data.data.dispatch_orders.online_team_accept;
        }
        this.loading = false;
        }, error => {
        this.loading = false;
        });
    }

    goBack() {
        window.history.back();
    }

    orderStatusUpdate(){
        this.loading = true;
        const values = {
          'order_id' :this.orderId,
          'order_overall_totall' : this.orderDetails.order_overall_totall,
          'message' : this.orderStatusName,
          'billing_name': this.orderDetails.billing_name,
        };
        this.apiService.postData(values ,'orderStatusUpdateOld').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                this.goBack();
            } else {
                this.toastr.error(data.message);
            }
            this.loading = false;
        });
    }

    clearInterval(){
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
}
