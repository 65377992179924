import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FormBuilder, FormGroup } from "@angular/forms";


@Component({
    selector: 'app-banner-image',
    templateUrl: './banner-image.component.html',
    styleUrls: ['./banner-image.component.css']
})
export class BannerImageComponent implements OnInit {

    imageChangedEvent: any = '';
    croppedImage: any = '';
    loading: boolean = false;
    loadingBtn: boolean;
    public files: NgxFileDropEntry[] = [];
  selectedFile: File;
  selectedFileUrl: string | ArrayBuffer;

  filePath: string;
  myForm: FormGroup;
  selectedFileName: any = "Choose image";

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private imageService: ImageService,
        private imageCompress: NgxImageCompressService,
        public fb: FormBuilder
    ) {
      this.myForm = this.fb.group({
        img: [null],
       })
     }

    ngOnInit() {
    }
    imagePreview(e) {
      const file = (e.target as HTMLInputElement).files[0];

      this.myForm.patchValue({
        img: file
      });

      this.myForm.get('img').updateValueAndValidity()
      this.selectedFile = e.target.files[0];
      this.selectedFileName = e.target.files[0].name;
      const reader = new FileReader();
      reader.onload = () => {
        this.filePath = reader.result as string;
      }
      reader.readAsDataURL(file)
    }

    submit() {
      this.imageUpdateBanner(this.selectedFile);
    }


    imageUpdateBanner(imageFile) {
      this.loading = true;
        const formData: FormData = new FormData();
        formData.append('banner_id', this.route.snapshot.paramMap.get('id'));
        formData.append('banner_image',imageFile);
        this.apiService.postData(formData, 'imageUpdateBanner').subscribe(data => {
        if (data.error === false) {
            this.toastr.success(data.message);
            this.router.navigate(['/banner']);
        } else {
            this.toastr.error(data.message);
        }
        this.loadingBtn = false;
        this.loading = false;
        }, error => {
        this.loadingBtn = false;
        this.loading = false;
        });
    }

}
