import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ImageService } from 'src/app/services/image.service';
import { Lightbox } from 'ngx-lightbox';
import * as RecordRTC from 'recordrtc';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToWords } from 'to-words';
import { DatePipe, formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { element } from 'protractor';
// import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import * as saveAs from 'file-saver';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-delivered-orders-details',
    templateUrl: './delivered-orders-details.component.html',
    styleUrls: ['./delivered-orders-details.component.css'],
    providers: [DatePipe]
})
export class DeliveredOrdersDetailsComponent implements OnInit {
    //@ViewChild('audioOption') audioPlayerRef: ElementRef;
    public counts = ["Submitted","Confirmed","Preparing", "Out For Delivery","Delivered"];
    private album: any = [];
    private orderImage:any = [];
    private addonImage:any = [];
    searchField: FormControl = new FormControl();
    orderDetails: any = [];
    p = 1;
    imageURL;
    trackId = '';
    loading: boolean;
    cityName;
    regionId;
    pincodeList: any;
    locationDetailsId = 0;
    orderId = this.route.snapshot.paramMap.get('id');
    blob: Blob;
    driverId = 0;
    orderStatus: any;
    pushToDispatch: any;
    dispatchAcceptance: any;
    driver_accepted_at: any;
    dispatchAcceptedAt:any;
    picked_up:any;
    picked_up_at:any;
    orderDeliveredAt:any;
    push_to_driver_id:any;
    push_to_driver_success:any;
    dispatchImagesProductId:any;
    admin_approved_at:any;
    dispatchAcceptancee: any;
    adminAcceptancee: any;
    driver_acceptancee:any;
    getDispatchOrderId:any= [];
    getDriverOrderId:any = [];
    orderStatusName;
    dispatch_order_id;
    dispatchImagesList:any = [];
    cakeDoneImages;
    onlineTeamAccept;
    driverList:any = [];
    driver_order_id;
    dispatchOrderStatus:any = [];
    audiolaunch:any =[];
    viewComment: boolean;
    comment: string;
    order_status:any= "Submitted";
    title = 'voice record';
    record:any="";
    recording = false;
    url:any="";
    str:any="";
    error:any="";
    voicenotes:any="";
    getvoice:any="";
    rejectionComment: string;
    addonDetails: any = [];
    shopList: any;
    shopId: any = 0;
    deliveryMode: any;
    getShopOrderId: any;
    pushed_to_driver_at: any;
    shop_accepted_at: any;
    shop_created_at: any;
    driverDeliveredAt: any;
    driverUploadImage: any = [];
    cityId: any;
    viewDrivers: boolean;
    productDetails: any = [];
    subTotal = 0;
    subTotalWithoutShipping = 0;
    gstArray: any = [];
    gstValue: any = [];
    uniqueGst: any = [];
    uniqueHsn: any = [];
    gstObj: any = {};
    orderOverallTotal = 0;
    overallTotal = 0;
    roundOffValue:any = 0;
    calculateGst = 0;
    showInvoice: boolean = true;
    deliveryFee = 0;
    shippingSplit = 0;
    discountedAmount:any;
    promoCodePercentage:any
    @ViewChild('htmlData', { static: false }) htmlData: ElementRef;
    addOnQty: any;
    gstForHSNTable: any;
    checking: any;
    addonTotalTaxAmount: number;
    addressarray:any=[];
    shopaddress:any=[];
    region:any=[];
    // new invoice variable  declare start
    productNewDetails:any=[];
    AddonNewDetails:any=[];
    deliver_fee:number = 0;
    deliver_fee_gst:number = 0;
    deliver_without_gst:number = 0;
    df_cgst_tax:number = 0;
    df_sgst_tax:number = 0;
    df_tax_value:number = 0;
    withoutTaxOverAllTotal: any = 0;
    withoutTaxSubtotal:number=0;
    withoutTaxProduct_Deliveryfees:any = 0;
    TaxSubtotal:number=0;
    overallAddSubtotalTaxtotal:number=0;
    df_cgst_value:number=0;
    df_sgst_value:number= 0;
    cgst_value:number=0;
    sgst_value:number= 0;
    roundOffValues:any =0;
    newResult: any=[];
    newtaxresult: any = [];
    taxableValueTotal:any = 0;
    cgstAmountTotal:any = 0;
    sgstAmountTotal:any = 0;
    totalTaxAmount:any = 0;
    customerDetails:any = [];
    // end variable declare start

    constructor(private apiService: ApiService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private imageService: ImageService,
        private _lightbox: Lightbox
    ) {}

    open(type: String,index: number): void {  // open lightbox
        if(type == "ORDER"){
            this._lightbox.open(this.orderImage, index);
        }else if(type == "DISPATCH"){
            this._lightbox.open(this.album, index);
        }else if(type == "ADDONS"){
            this._lightbox.open(this.addonImage, index);
        }
    }

    downloadFile(): void {
      /* pass here the table id */
      let element = document.getElementById('invoice-table');
      // console.log('element =', element);

      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      var download = "INVOICE_ORDER_NO_#"+this.orderId+".xlsx";

      /* save to file */
      XLSX.writeFile(wb, download);

    }


    close(): void {  // close lightbox programmatically
      this._lightbox.close();
    }

    ngOnInit() {
        this.getOrderDetails();
        this.getDispatchOrderByOrderId();
        this.getShopOrderByOrderId();
        this.imageURL = this.imageService.getMinImageurl();
    }

    downLoadInvoice() {
        this.apiService.getPdf('generateInvoicePdf', this.orderId).subscribe((data:any) => {
            this.blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "INVOICE_ORDER_NO_#"+this.orderId+".pdf";
            link.click();
        });
    }

    dowloadPDF() {
        let DATA: any = document.getElementById('htmlData');
        html2canvas(DATA).then((canvas) => {
            let fileWidth = 208;
            let fileHeight = (canvas.height * fileWidth) / canvas.width;
            const FILEURI = canvas.toDataURL('image/png');
            let PDF = new jsPDF('p', 'mm', 'a4');
            let position = 0;
            PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
            PDF.save('INVOICE_ORDER_NO_#'+this.orderId+'.pdf');
        });
    }

    getAllShopDetailsByRegionId(){
        this.loading = true;
        this.apiService.getData('getAllShopDetailsByRegionId', this.regionId).subscribe((data) => {
            this.shopList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    displayStyle = "none";

    openPopup() {
        this.displayStyle = "block";
    }
    closePopup() {
        this.displayStyle = "none";
    }

    sanitize(url:string){
        return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    initiateRecording() {
        this.recording = true;
        let mediaConstraints = {
            video: false,
            audio: true
        };
        navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
    }

    successCallback(stream: MediaStream) {
        var options = {
            mimeType: "audio/wav",
            numberOfAudioChannels: 1
        };
        var StereoAudioRecorder = RecordRTC.StereoAudioRecorder; //Start Actuall Recording
        this.record = new StereoAudioRecorder(stream, options);
        this.record.record();
    }

    stopRecording() {
        this.recording = false;
        this.record.stop(this.processRecording.bind(this));
    }

    processRecording(blob: Blob | MediaSource) {
        this.url = URL.createObjectURL(blob);
        this.str=this.url;
    }

    errorCallback(error: any) {
        this.error = 'Can not play audio in your browser';
    }

    getVoice(){
        this.loading = true;
        this.apiService.getData('getVoicenotesss').subscribe((data) => {
            this.getvoice = data.data;
            //this.getvoice = atob(data.data);
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    store(){
        this.loading = true;
        //this.audiolaunch=btoa(this.url);
        // const value = { voicenotes: this.voicenotes}
        const value = { voicenotes: this.url}
        //const value = { voicenotes: this.audiolaunch}
        this.apiService.postData(value, 'postVoicenotesss').subscribe((data) => {

            this.toastr.success(data.message);
            this.loading = false;

        }, error => {
            this.loading = true;
        });
    }

    onChange(event){
        this.locationDetailsId = event;
    }

    getAllDriverList(){
        this.loading = true;
        this.apiService.getData('getAllDriverListByLocationId', this.locationDetailsId).subscribe((data) => {
            this.driverList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getAllLocationDetailsByRegionId(){
        this.loading = true;
        this.apiService.getData('getAllLocationDetailsByRegionId', this.regionId).subscribe((data) => {
            this.pincodeList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getDispatchOrderByOrderId(){
        this.loading = true;
        this.apiService.getData('getDispatchOrderByOrderId', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
            this.getDispatchOrderId = data.data;
            // this.dispatchOrderStatus = data.data[0].dispatch_order_status;
             console.log('dispatchOrderStatus ==',this.dispatchOrderStatus);
            if(this.getDispatchOrderId.length != 0){
                this.dispatch_order_id = data.data[0].dispatch_order_id;
                this.getDispatchImagesById();
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getDriverOrderByOrderId(){
        this.loading = true;
        this.apiService.getData('getDriverOrderByOrderId', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
            this.getDriverOrderId = data.data;
            if(this.getDriverOrderId.length != 0){
                this.driver_order_id = data.data[0].dispatch_order_id;
                this.push_to_driver_id=data.data[0].driver_id;
                this.driver_acceptancee = data.data[0].driver_acceptance;
                this.driver_accepted_at = data.data[0].accepted_at;
                this.picked_up = data.data[0].picked_up;
                this.picked_up_at = data.data[0].picked_up_at;
                this.pushed_to_driver_at = data.data[0].created_at;
                this.driverDeliveredAt = data.data[0].delivered_at;
                this.viewDrivers = data.data[0].shop_id ? true :  false;
                // this.getDispatchImagesById();
                if(data.data[0].driver_id != 0){
                    this.push_to_driver_success="driver Push success";
                }
                const album = {
                    src: this.getDriverOrderId[0].driver_uploaded_image,
                    caption: 'Driver Uploaded Image',
                    thumb: this.getDriverOrderId[0].driver_uploaded_image
                };
                this.driverUploadImage.push(album)
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getShopOrderByOrderId(){
        this.loading = true;
        this.apiService.getData('getShopOrderByOrderId', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
            this.getShopOrderId = data.data;
            if(this.getShopOrderId.length != 0){
                this.shop_accepted_at = data.data[0].accepted_at != null ? data.data[0].accepted_at : null;
                this.shop_created_at = data.data[0].created_at;
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getDispatchImagesById(){
        this.loading = true;
        this.apiService.getData('getDispatchImagesById', this.dispatch_order_id).subscribe((data) => {
        this.dispatchImagesList = data.data;
        this.dispatchImagesProductId = data.data[0].approve_status;
        if(this.dispatchImagesProductId!=0){
            this.admin_approved_at=data.data[0].admin_accepted_at;
        }
        this.dispatchImagesList.forEach(element => {
            const src = element.image;
            const caption = '';
            const thumb = element.image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };

            this.album.push(album)

        });
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    approveImage(dispatch_prepare_image_id="", dispatch_order_id=""){
        const value = {
            dispatch_prepare_image_id: dispatch_prepare_image_id,
            dispatch_order_id:dispatch_order_id
        };
        this.apiService.postData(value, 'approveImage').subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getDispatchImagesById();
                this.getOrderDetails();

            } else {
                this.toastr.warning(data.message);
                this.loading = false;

            }
        }, error => {
            this.loading = false;
        });
    }

    disApproveImage(dispatch_prepare_image_id="", dispatch_order_id="", rejectionComment){
        if(rejectionComment !== "" || rejectionComment !== null  ){
            const value = {
                dispatch_prepare_image_id: dispatch_prepare_image_id,
                dispatch_order_id:dispatch_order_id,
                comments:rejectionComment
            };
            this.apiService.postData(value, 'disApproveImage').subscribe(data => {
                if (data.error === false) {
                    this.toastr.success(data.message);
                    this.closePopup();
                    this.getDispatchImagesById();
                } else {
                    this.toastr.warning(data.message);
                    this.loading = false;
                }
            }, error => {
                this.loading = false;
            });
        }else{
            this.toastr.warning("Enter Comments for Rejection");
        }
    }

    onPushDispatch(){
        this.loading = true;
        const param = {
            location_details_id: this.locationDetailsId,
            order_id:this.orderId
        }
        this.apiService.postData(param, 'createDispatchOrder').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                this.getDispatchOrderByOrderId();
                this.loading = false;
            } else {
                this.toastr.warning(data.message);
                this.loading = false;
            }
        }, error => {
            this.loading = false;
        });
    }

    onPushDriver(){
        this.loading = true;
        const param = {
            location_details_id: this.locationDetailsId,
            order_id:this.orderId,
            driver_id: this.driverId
        }
        this.apiService.postData(param, 'createDriverOrder').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                this.getDispatchOrderByOrderId();
                this.getDriverOrderByOrderId();
                this.loading = false;
            } else {
                this.toastr.warning(data.message);
                this.loading = false;
            }
        }, error => {
            this.loading = false;
        });
    }

    onPushToShop(){
        this.loading = true;
        const param = {
            order_id:this.orderId,
            shop_id: this.shopId,
            region_id: this.regionId
        }
        this.apiService.postData(param, 'createShopOrder').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                this.getDispatchOrderByOrderId();
                if(this.deliveryMode == 'Door Step Delivery'){
                    this.getDriverOrderByOrderId();
                }else if(this.deliveryMode == 'Pick Up'){
                    this.getShopOrderByOrderId();
                }
                this.loading = false;
            } else {
                this.toastr.warning(data.message);
                this.loading = false;
            }
        }, error => {
            this.loading = false;
        });
    }


    getOrderDetails() {
        this.loading = true;
        this.apiService.getData('getOrderDetails', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
        this.orderDetails = data.data;
        this.addressarray = [];
        this.shopaddress = [];
        this.region = [];
        this.customerDetails = data.data.customer_details;
        this.addressarray =   this.orderDetails.ordered_address;
        this.shopaddress =   this.orderDetails.shops;
        this.region =   this.orderDetails.region;
        this.promoCodePercentage = this.orderDetails.promo_code_percentage
        this.discountedAmount = data.data.promo_code_value
        this.orderOverallTotal = this.orderDetails.order_overall_totall;
        this.deliveryFee = this.orderDetails.deliver_fee;
        this.cgst_value = this.sgst_value = 0
        this.cgst_value = this.orderDetails.cgst_value;
        this.sgst_value = this.orderDetails.sgst_value;
        

        // -------------------------------------------start new invoice calculation-----------------

        this.productNewDetails = [];
        const orderProduct = this.orderDetails.ordered_products; // Product Details Add in New Array
        // console.log('weight',product.variation.weight[0].weight_name);
        if(orderProduct != ''){  // Apply Promo Code Condtion
        //   console.log('promoCodePercentage ==', this.promoCodePercentage);
            if(this.promoCodePercentage != null){
                for (let i = 0; i < orderProduct.length; i++) {
                    this.productNewDetails.push({
                        promo_percentage_apply_value: Number(  ((orderProduct[i].product_total )/((orderProduct[i].product_details[0].tax.tax_percentage + 100) / 100))/ Number(this.promoCodePercentage)  ),
                        without_tax_value: Number((orderProduct[i].product_total )/((orderProduct[i].product_details[0].tax.tax_percentage + 100) / 100))- Number(((orderProduct[i].product_total )/((orderProduct[i].product_details[0].tax.tax_percentage + 100) / 100))/ Number(this.promoCodePercentage)),
                        egg_or_eggless: orderProduct[i].egg_or_eggless,
                        flavour: orderProduct[i].flavour,
                        flavour_id: orderProduct[i].flavour_id,
                        message_on_cake: orderProduct[i].message_on_cake,
                        order_id: orderProduct[i].order_id,
                        order_product_id: orderProduct[i].order_product_id,
                        hsn: orderProduct[i].product_details[0].hsn,
                        tax_percentage : orderProduct[i].product_details[0].tax.tax_percentage,
                        product_details: orderProduct[i].product_details,
                        product_discount_price: orderProduct[i].product_discount_price,
                        product_id: orderProduct[i].product_id,
                        product_price: orderProduct[i].product_price,
                        product_quantity: orderProduct[i].product_quantity,
                        product_size_id: orderProduct[i].product_size_id,
                        total: orderProduct[i].product_total,
                        size: orderProduct[i].size,
                        variation: orderProduct[i].variation,
                        variation_id: orderProduct[i].variation_id,
                    })
                }
            }else{ //Not Apply Promo code Condtion
                for (let i = 0; i < orderProduct.length; i++) {
                    this.productNewDetails.push({
                        without_tax_value: Number((orderProduct[i].product_total )/((orderProduct[i].product_details[0].tax.tax_percentage + 100) / 100)),
                        egg_or_eggless: orderProduct[i].egg_or_eggless,
                        flavour: orderProduct[i].flavour,
                        flavour_id: orderProduct[i].flavour_id,
                        message_on_cake: orderProduct[i].message_on_cake,
                        order_id: orderProduct[i].order_id,
                        order_product_id: orderProduct[i].order_product_id,
                        hsn: orderProduct[i].product_details[0].hsn,
                        tax_percentage : orderProduct[i].product_details[0].tax.tax_percentage,
                        product_details: orderProduct[i].product_details,
                        product_discount_price: orderProduct[i].product_discount_price,
                        product_id: orderProduct[i].product_id,
                        product_price: orderProduct[i].product_price,
                        product_quantity: orderProduct[i].product_quantity,
                        product_size_id: orderProduct[i].product_size_id,
                        total: orderProduct[i].product_total,
                        size: orderProduct[i].size,
                        variation: orderProduct[i].variation,
                        variation_id: orderProduct[i].variation_id,
                    })
                }
            }
            // console.log('productNewDetails =', this.productNewDetails)
            this.deliver_fee = this.deliver_fee_gst = this.deliver_without_gst = this.df_cgst_value = this.df_sgst_value = this.df_cgst_tax = this.df_sgst_tax = this.df_tax_value = 0;
            this.deliver_fee = this.orderDetails.deliver_fee;
            this.deliver_fee_gst = this.orderDetails.deliver_fee_gst;
            if(this.deliver_fee != 0 && this.deliver_fee_gst != null){ // If Delivery Fees Come Details Collected
                this.df_cgst_tax = Number(this.deliver_fee_gst / 2);
                this.df_cgst_value = Number(this.orderDetails.df_cgst_value);
                this.df_sgst_tax = Number(this.deliver_fee_gst / 2);
                this.df_sgst_value = Number(this.orderDetails.df_sgst_value);
                this.deliver_fee_gst = this.orderDetails.deliver_fee_gst;
                this.df_tax_value = (this.df_cgst_value + this.df_sgst_value);
                this.deliver_without_gst = Number((this.deliver_fee) / ((this.deliver_fee_gst + 100) / 100));
            }
            this.AddonNewDetails = [];
            const addonDetail = data.data.ordered_addons;

            if(addonDetail != ''){ // If Addons Come Details Collected
                for (let i = 0; i < addonDetail.length; i++) {
                    this.AddonNewDetails.push({
                        addon_id: addonDetail[i].addon_id,
                        customer_id: addonDetail[i].customer_id,
                        hsn: addonDetail[i].hsn,
                        image: addonDetail[i].image,
                        order_addons_id: addonDetail[i].order_addons_id,
                        order_id: addonDetail[i].order_id,
                        price: addonDetail[i].price,
                        product_name: addonDetail[i].product_name,
                        total: addonDetail[i].total,
                        quantity: addonDetail[i].quantity,
                        tax: addonDetail[i].tax,
                        tax_percentage: addonDetail[i].tax.tax_percentage,
                        without_tax_value: Number((addonDetail[i].total )/((addonDetail[i].tax.tax_percentage + 100) / 100)),
                    })
                }
                this.productNewDetails = this.productNewDetails.concat(this.AddonNewDetails); // add Product details + addon details same array
            }
            this.withoutTaxSubtotal = this.TaxSubtotal = this.withoutTaxProduct_Deliveryfees =  this.withoutTaxOverAllTotal = 0;
            this.productNewDetails.forEach(element => {
                this.TaxSubtotal += Number((+element.without_tax_value).toFixed(2));
            });
            this.withoutTaxSubtotal = this.TaxSubtotal + this.deliver_without_gst;
            this.withoutTaxOverAllTotal = this.withoutTaxSubtotal;
            this.withoutTaxProduct_Deliveryfees = this.withoutTaxSubtotal + this.df_sgst_value + this.df_cgst_value;
        }

        const array =[];
        if(this.promoCodePercentage != null){   // Apply Promo Code Condtion
            for (let i = 0; i < this.productNewDetails.length; i++) { // collect all hsn . tax percentage and all gst details
                array.push({
                    hsn: this.productNewDetails[i].hsn,
                    tax_value: Number(this.productNewDetails[i].tax_percentage / 100) * Number(this.productNewDetails[i].without_tax_value),
                    without_tax_value: Number(this.productNewDetails[i].without_tax_value),
                    tax_percentage: this.productNewDetails[i].tax_percentage,
                    total : this.productNewDetails[i].total,
                    tax_cgst: this.productNewDetails[i].tax_percentage/2,
                    tax_cgst_value: Number(Number(this.productNewDetails[i].tax_percentage / 100) * Number(this.productNewDetails[i].without_tax_value) / 2),
                    tax_sgst: this.productNewDetails[i].tax_percentage/2,
                    tax_sgst_value: Number(Number(this.productNewDetails[i].tax_percentage / 100) * Number(this.productNewDetails[i].without_tax_value) / 2),
                });
            }
        } else {  //Not Apply Promo Code Condtion
            for (let i = 0; i < this.productNewDetails.length; i++) { // collect all hsn . tax percentage and all gst details
                array.push({
                    hsn: this.productNewDetails[i].hsn,
                    without_tax_value: Number(this.productNewDetails[i].without_tax_value),
                    tax_percentage: this.productNewDetails[i].tax_percentage,
                    tax_value: Number(this.productNewDetails[i].total - this.productNewDetails[i].without_tax_value),
                    total : this.productNewDetails[i].total,
                    tax_cgst: this.productNewDetails[i].tax_percentage/2,
                    tax_cgst_value: Number((this.productNewDetails[i].total - this.productNewDetails[i].without_tax_value) / 2),
                    tax_sgst: this.productNewDetails[i].tax_percentage/2,
                    tax_sgst_value: Number((this.productNewDetails[i].total - this.productNewDetails[i].without_tax_value) / 2),
                });
            }
        }
        var result = []; // get all tax details and duplicate hsn values to add in same hsn
        if(this.promoCodePercentage != null){  // Apply Promo Code Condtion
            array.forEach(function (a) {
                if (!this[a.hsn]) {
                    this[a.hsn] = {
                        hsn: a.hsn,
                        tax_percentage : 0,
                        tax_cgst : 0,
                        tax_cgst_value : 0 ,
                        tax_sgst : 0,
                        tax_sgst_value : 0,
                        tax_value: 0,
                        without_tax_value: 0,
                        total : 0
                    };
                    result.push(this[a.hsn]);
                }
                this[a.hsn].without_tax_value += a.without_tax_value;
                this[a.hsn].tax_percentage = a.tax_percentage;
                this[a.hsn].tax_value += a.tax_value;
                this[a.hsn].tax_cgst = a.tax_cgst;
                this[a.hsn].tax_cgst_value += a.tax_cgst_value;
                this[a.hsn].tax_sgst = a.tax_sgst;
                this[a.hsn].tax_sgst_value += a.tax_sgst_value;
                this[a.hsn].total += a.total;
            }, Object.create(null));
            this.newResult = [];
            this.newResult = result; // merge details change in variable
        } else {
            array.forEach(function (a) {  // Not Apply Promo Code Condtion
                if (!this[a.hsn]) {
                    this[a.hsn] = {
                        hsn: a.hsn,
                        tax_percentage : 0,
                        tax_cgst : 0,
                        tax_cgst_value : 0 ,
                        tax_sgst : 0,
                        tax_sgst_value : 0,
                        tax_value: 0,
                        without_tax_value: 0,
                        total : 0
                    };
                    result.push(this[a.hsn]);
                }
                this[a.hsn].without_tax_value += a.without_tax_value;
                this[a.hsn].tax_percentage = a.tax_percentage;
                this[a.hsn].tax_value += a.tax_value;
                this[a.hsn].tax_cgst = a.tax_cgst;
                this[a.hsn].tax_cgst_value += a.tax_cgst_value;
                this[a.hsn].tax_sgst = a.tax_sgst;
                this[a.hsn].tax_sgst_value += a.tax_sgst_value;
                this[a.hsn].total += a.total;
            }, Object.create(null));
            this.newResult = [];
            this.newResult = result; // merge details change in variable
        }
		//-----------------------------------------------cgst and sgst calculation- start
        var checking = [];
        array.forEach(function (a) {
            if (!this[a.tax_cgst]) {
                this[a.tax_cgst] = {
                    tax_cgst: a.tax_cgst,
                    tax_sgst: a.tax_sgst,
                    tax_cgst_value: 0,
                    tax_sgst_value: 0,
                };
                checking.push(this[a.tax_cgst]);
            }
            this[a.tax_cgst].tax_cgst_value += a.tax_cgst_value;
            this[a.tax_cgst].tax_sgst_value += a.tax_sgst_value;

        }, Object.create(null));
        this.newtaxresult = [];
        this.newtaxresult = checking; // merge details change in variable
        console.log('newtaxresult', this.newtaxresult);
        //-----------------------------------------------cgst and sgst calculation--end

        this.taxableValueTotal = this.cgstAmountTotal = this.sgstAmountTotal = this.totalTaxAmount = 0 ;
        this.newResult.forEach(element => {
            this.taxableValueTotal += Number(+element.without_tax_value);
            this.cgstAmountTotal += Number(+element.tax_cgst_value);
            this.sgstAmountTotal += Number(+element.tax_sgst_value);
        });
        this.taxableValueTotal = this.taxableValueTotal + this.deliver_without_gst;
        this.cgstAmountTotal = this.cgstAmountTotal + this.df_cgst_value ;
        this.sgstAmountTotal = this.sgstAmountTotal + this.df_sgst_value;
        this.totalTaxAmount = Number(this.cgstAmountTotal.toFixed(2)) + Number(this.sgstAmountTotal.toFixed(2));
        let total_cgst_value = 0;
        let total_sgst_value = 0;
        this.newtaxresult.forEach(element => {
            if(element.tax_cgst_value != undefined ){
                total_cgst_value += Number((+element.tax_cgst_value).toFixed(2));
            }
            if(element.tax_sgst_value != undefined ){
                total_sgst_value += Number((+element.tax_sgst_value).toFixed(2));
            }
        });
        let total = 0;
        let overalltotals = 0;
        this.roundOffValues = 0;  // round off find
        total = Number(this.withoutTaxOverAllTotal.toFixed(2));
        overalltotals = total + total_cgst_value + total_sgst_value + this.df_cgst_value + this.df_sgst_value;
        this.roundOffValues = Number(Number(this.orderOverallTotal)- overalltotals);

        // ---------------End new invoice calculation-------------by Palani

        this.cityName = data.data.region.city.name;
        this.cityId = data.data.region.city_id;
        this.regionId = data.data.region_id;
        this.orderId = data.data.order_id;
        this.orderStatus= data.data.order_status;
        this.orderStatusName =  this.orderStatus;
        this.deliveryMode = data.data.delivery_mode;
        this.orderDeliveredAt= data.data.order_delivered_at;
        this.orderDetails.ordered_products.forEach(element => {
            const src = element.product_details[0].images[0].product_image;
            const caption = '';
            const thumb = element.product_details[0].images[0].product_image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this.orderImage.push(album)
        });
        this.addonDetails.forEach(element => {
            const src = element.image;
            const caption = '';
            const thumb = element.image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this.addonImage.push(album)
        });
        if(data.data.dispatch_orders){
            this.cakeDoneImages = data.data.dispatch_orders.cake_done_image;
            this.onlineTeamAccept = data.data.dispatch_orders.online_team_accept;
            this.locationDetailsId = data.data.dispatch_orders.location_details_id;

            this.dispatchAcceptancee = data.data.dispatch_orders.dispatch_order_status;
            this.dispatchAcceptedAt=data.data.dispatch_orders.dispatch_accepted_at;
            this.adminAcceptancee = data.data.dispatch_orders.online_team_accept;
        }
        if(this.deliveryMode == 'Door Step Delivery'){
            // this.getAllDriverList();
        }
        this.getDriverOrderByOrderId();
        this.getShopOrderByOrderId();
        this.getAllShopDetailsByRegionId();
        this.pushToDispatch = data.data.push_to_dispatch;
        this.dispatchAcceptance=data.data.dispatchAcceptance;
        if (this.orderDetails.order_tracking_id) {
            this.trackId = this.orderDetails.order_tracking_id;
        }
        this.getAllLocationDetailsByRegionId();
        // this.getAllDriverList();
        this.loading = false;
        setTimeout(()=>{
            // console.log('15 sec function call');
            this.fetchOrdersDetailsRegressively();  //Call back invoice fuction after 14 sec again
        }, 15000);
        }, error => {
            this.loading = false;
        });
    }

    convertToWords(value) {
        const toWords = new ToWords({
            localeCode: 'en-IN',
            converterOptions: {
                currency: true,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: true,
                currencyOptions: { // can be used to override defaults for the selected locale
                    name: 'Rupee',
                    plural: 'Rupees',
                    symbol: '₹',
                    fractionalUnit: {
                    name: 'Paisa',
                    plural: 'Paise',
                    symbol: '',
                    },
                }
            }
        });
        return `INR ${toWords.convert(value).toUpperCase()}`
    }

    fetchOrdersDetailsRegressively(){
        this.getOrderDetails();
    }

    goBack() {
        window.history.back();
    }

    updateStatus(){
        this.apiService.getData('orderStatusUpdate', `${this.orderId}/${this.orderStatusName}`).subscribe((data) => {
        if (data.error === false) {
            this.toastr.success(data.message);
            this.getOrderDetails();
        } else {
            this.toastr.error(data.message);
        }
        });
    }

    onChangeStatus(event){
        this.orderStatusName = event;
    }

    onChangeDriver(event){
        this.driverId = event;
    }

    onChangeShop(event){
        this.shopId = event;
    }

    orderTrackingUpdate(id) {
        this.apiService.getData('orderTrackingUpdate', `${id}/${this.trackId}`).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                // console.log('getOrderDetails ',this.getOrderDetails());
            } else {
                this.toastr.error(data.message);
            }
        });
    }

    openCommentPopUp(comments="") {
        this.viewComment = true;
        this.comment = comments;
    }

    closeCommentPopUp() {
        this.viewComment = false;
    }

   
}
