import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.css']
})
export class ShopDetailsComponent implements OnInit {
  modalBoxName;
  loadingBtn = false;
  loading = false;
  showPopup = false;
  searchField: FormControl = new FormControl();
  errorMsg: any = [];
  sno = 1;
  locationDetailsList:any = [];
  regionList: any;
  regionId: any;
  shopCode;
  shopName;
  pincode;
  mobileNo;
  address;
  regionName:any="";

  editRegionId: any;
  editLocationCode;
  editLocationName;
  editPincode;
  editMobileNo;
  editAddress;
  locationDetailsId;
  editUserPassword;
  isActive = false;
  isActiveCon = false;

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit() {
    this.listAllShops();
    this.listAllRegions();
    this.search();
  }

  listAllShops(){
    this.loading = true;
    this.apiService.getData('getAllShopDetails').subscribe((data) => {
        this.locationDetailsList = data.data;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }

   listAllRegions(){
    this.loading = true;
    this.apiService.getData('getAllActiveRegionList').subscribe((data) => {
        this.regionList = data.data;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }

   store(){
    this.loadingBtn = true;
    const value = {
         region_id: this.regionId,
         shop_name: this.shopName,
         shop_code: this.shopCode,
         address: this.address,
          pincode: this.pincode,
          mobile_no:this.mobileNo,
          password: this.editUserPassword,
        }
    this.apiService.postData(value, 'createShopDetails').subscribe((data) => {
        if (data.error === false) {
            this.toastr.success(data.message);
            this.listAllShops();
            this.regionId = '';
            this.shopCode = '';
            this.shopName = '';
            this.address = '';
            this.mobileNo='';
            this.pincode=''
            this.popUpClose();
            this.loadingBtn = false;
        } else {
            this.toastr.warning(data.message);
            this.loadingBtn = false;
        }
    }, error => {
        this.loadingBtn = false;
    });
  }

  activateShopDetails(shop_details_id){
    console.log(shop_details_id);
    this.loading = true;
    this.apiService.getData('activateShopDetails', shop_details_id).subscribe((data) => {
      if (data.error === false) {
        this.toastr.success(data.message);
        this.listAllShops();
        this.loadingBtn = false;
    } else {
        this.toastr.warning(data.message);
        this.loadingBtn = false;
    }
    }, error => {
        this.loading = false;
    });
  }

  deActivateShopDetails(shop_details_id){
    console.log(shop_details_id);
    this.loading = true;
    this.apiService.getData('deActivateShopDetails', shop_details_id).subscribe((data) => {
      if (data.error === false) {
        this.toastr.success(data.message);
        this.listAllShops();
        this.loadingBtn = false;
    } else {
        this.toastr.warning(data.message);
        this.loadingBtn = false;
    }
    }, error => {
        this.loading = false;
    });
  }

  deleteShopDetails(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this Shop Detail',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
    }).then((result) => {
        if (result.value) {
            this.apiService.getData('deleteShopDetails', id).subscribe((data) => {
                if (data.error === false) {
                    this.toastr.success(data.message);
                    this.listAllShops();
                } else {
                    this.toastr.error(data.message);
                }
            });

            Swal.fire(
                'Deleted!',
                'Your Shop detail has been deleted.',
                'success'
            );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
                'Cancelled',
                'Your Shop detail file is safe :)',
                'error'
            );
        }
    });
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  updateLocationDetails() {
    if(this.editMobileNo == null || this.editMobileNo == "" || this.editMobileNo.length != 10 ){
        this.toastr.warning('Please Enter Contact Mobile No');
        return false;
    }else if(this.editPincode == null || this.editPincode == "" || this.editPincode.toString().length != 6 ){
        this.toastr.warning('Please Enter Pin Code');
        return false;
    }else{

      this.loadingBtn = true;
      const value = {
          shop_details_id:this.locationDetailsId,
          region_id: this.editRegionId,
          shop_name: this.editLocationName,
          shop_code: this.editLocationCode,
          address: this.editAddress,
          pincode: this.editPincode,
          mobile_no:this.editMobileNo,
          password: this.editUserPassword,
        }
      this.apiService.postData(value, 'updateShopDetails').subscribe((data) => {
        if (data.error === false) {
            this.toastr.success(data.message);
            this.popUpClose();
            this.modalBoxName = 'Create';
            this.listAllShops();
            this.loadingBtn = false;
        } else {
            this.toastr.warning(data.message);
            this.loadingBtn = false;
        }
     });
    }
  }

   openModalBox(id = '', region_id='', location_code='', location_name='', address='', pincode='', mobile_no = '') {
    if (id) {
        this.modalBoxName = 'Edit';
        this.locationDetailsId = id;
        this.editRegionId = region_id;
        this.editLocationName = location_name;
        this.editLocationCode = location_code;
        this.editAddress = address;
        this.editPincode = pincode;
        this.editMobileNo = mobile_no;
        this.editMobileNo = mobile_no;
        this.editUserPassword = '';
    } else {
        this.modalBoxName = 'Create';
        this.editUserPassword = "";
    }
    this.showPopup = true;
  }

  search() {
    this.searchField.valueChanges.pipe(debounceTime(200), distinctUntilChanged(), switchMap((query) =>
        this.apiService.searchData('searchLocationDetails', query)
    )).subscribe((result) => {
        if (this.searchField.value === '') {
            this.listAllShops();
            return false;
        }
        if (result.data.length === 0) {
            this.locationDetailsList = [];
        } else {
            this.locationDetailsList = result.data;
        }
    });
  }

  onChange(event){
    this.regionId = event;
    this.editRegionId = event;
    console.log(this.regionId);
   }

  popUpClose() {
    this.showPopup = false;
    this.errorMsg = [];
}
passwordVisible() {
  this.isActive = true;
}

passwordinVisible() {
  this.isActive = false;
}

conPasswordVisible() {
  this.isActiveCon = true;
}

conPasswordinVisible() {
  this.isActiveCon = false;
}
}
