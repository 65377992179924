import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-delivery-charge',
  templateUrl: './delivery-charge.component.html',
  styleUrls: ['./delivery-charge.component.css']
})
export class DeliveryChargeComponent implements OnInit {
  loading = false;
  dataList: any = [];
  sno = 1;
  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit() {
    this.listAllDeliveryCharges();
  }
  listAllDeliveryCharges(){
    this.loading = true;
    this.apiService.getData('getAllDeliveryCharge').subscribe((data) => {
        this.dataList = data.data;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }
}
