import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  modalBoxName;
  loadingBtn = false;
  loading = false;
  showPopup = false;
  enquiryList:any=[];
  sno = 1;
  previewImageUrl: String;

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit() {
    this.listAllEnquiry()
  }

  listAllEnquiry() {
    this.loading = true;
    this.apiService.getData('getAllActiveEnquiry').subscribe((data) => {
        this.enquiryList =[];
        this.enquiryList = data.data;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
 }
 showWarning(id) {
  Swal.fire({
      title: 'Are you sure?',
      text: 'Are You sure to close this Enquiry',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
  }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.apiService.getData('closeEnquiry', id).subscribe((data) => {
          if (data.error === false) {
            Swal.fire(
              'Deleted!',
              'Your Enquiry has been deleted.',
              'success'
          );
            this.listAllEnquiry();
            this.loadingBtn = false;
        } else {
            this.toastr.warning(data.message);
            this.loadingBtn = false;
        }
        }, error => {
            this.loading = false;
        });


      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
              'Cancelled',
              'Your Enquiry detail is safe :)',
              'error'
          );
      }
  });
}
closeEnquiry(id){
  alert(id)
  this.loading = true;
  this.apiService.getData('closeEnquiry', id).subscribe((data) => {
    if (data.error === false) {
      this.toastr.success(data.message);
      this.listAllEnquiry();
      this.loadingBtn = false;
  } else {
      this.toastr.warning(data.message);
      this.loadingBtn = false;
  }
  }, error => {
      this.loading = false;
  });
}
previewImage(src: String){
  this.previewImageUrl = src;
  document.getElementById("imagePreviewButton").click();
}
}
