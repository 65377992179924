import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

let head = new HttpHeaders();
let headers = head.set('Accept', 'application/json');
@Injectable({
    providedIn: 'root'
})
export class ApiService {
     apiURL = 'https://blaackforestcakes.com/bfserver/api'; // Live
    // apiURL = 'https://blaack-api.chronoinfotech.in/api'; // Subdomain
    //   apiURL = "http://localhost:8000/api";

    constructor(private http: HttpClient) { }

    getPdf(url:any, params:any = '') {
        headers = headers.set('Accept', 'application/json').set('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const httpOptions = {headers, responseType: 'blob' as 'json' };

        if (params) {
            return this.http.get<any>(`${this.apiURL}/` + url + `/` + params,  httpOptions );
        }
        return this.http.get(`${this.apiURL}/` + url, httpOptions);
      }

    private getToken() {
        headers = head.set('Authorization', `Bearer ${localStorage.getItem('user_token')}`);
    }

    public getData(url, params: any = '') {
        this.getToken();
        if (params) {
            return this.http.get<any>(`${this.apiURL}/` + url + `/` + params, { headers });
        }
        return this.http.get<any>(`${this.apiURL}/` + url, { headers });
    }

    public postData(data, url) {
        this.getToken();
        return this.http.post<any>(`${this.apiURL}/` + url, data, { headers });
    }

    public searchData(url, params: any = '', paginate:boolean = false, index = 0) {
        this.getToken();
        params = params === '' ? null : params;
        if (!paginate) {
            return this.http.get<any>(`${this.apiURL}/` + url + `/` + params, { headers });
        } else {
            // return this.http.get<any>(`${this.apiURL}/` + url + `/` + params, { headers });

            return this.http.get<any>(`${this.apiURL}/` + url + `/` + `${params}?page=${index}`, { headers } )
        }
    }

    public index(params) {
        this.getToken();
        return this.http.get<any>(`${this.apiURL}/` + params, { headers });
    }

    public store(params, data) {
        return this.http.post<any>(`${this.apiURL}/` + params, data, { headers });
    }

    public show(params) {
        return this.http.get<any>(`${this.apiURL}/` + params, { headers });
    }

    public update(params, data) {
        return this.http.post<any>(`${this.apiURL}/` + params + '?_method=PUT', data, { headers });
    }

    public destroy(params) {
        return this.http.delete<any>(`${this.apiURL}/` + params, { headers });
    }

}

