import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageService } from 'src/app/services/image.service';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import * as RecordRTC from 'recordrtc';
import Pusher from 'pusher-js';
import { Howl } from 'howler';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

    //@ViewChild('audioOption') audioPlayerRef: ElementRef;
    public counts = ["Submitted","Confirmed","Preparing", "Out For Delivery","Delivered"];
    private album: any = [];
    private orderImage:any = [];
    private addonImage:any = [];
    private driverUploadImage:any = [];
    searchField: FormControl = new FormControl();
    orderDetails: any = [];
    p = 1;
    imageURL;
    trackId = '';
    loading: boolean;
    cityName;
    regionId;
    pincodeList: any;
    locationDetailsId = 0;
    orderId;
    driverId = 0;
    orderStatus: any;
    pushToDispatch: any;
    dispatchAcceptance: any;
    driver_accepted_at: any;
    dispatchAcceptedAt:any;
    picked_up:any;
    picked_up_at:any;
    orderDeliveredAt:any;
    push_to_driver_id:any;
    push_to_driver_success:any;
    dispatchImagesProductId:any;
    admin_approved_at:any;
    dispatchAcceptancee: any;
    adminAcceptancee: any;
    driver_acceptancee:any;
    getDispatchOrderId:any= [];
    getDriverOrderId:any = [];
    orderStatusName;
    dispatch_order_id;
    dispatchImagesList:any = [];
    cakeDoneImages;
    onlineTeamAccept;
    driverList:any = [];
    driver_order_id;
    dispatchOrderStatus:any;
    audiolaunch:any =[];
    viewComment: boolean;
    comment: string;
    showDatePicker: boolean = false;
    scheduleToDispatch: any = false;
    order_status:any= "Submitted";
    title = 'voice record';
    record:any="";
    recording = false;
    url:any="";
    str:any="";
    error:any="";
    voicenotes:any="";
    getvoice:any="";
    rejectionComment: string;
    addonDetails: any = [];
    shopList: any;
    shopId: any = 0;
    deliveryMode: any;
    getShopOrderId: any;
    viewSchedule: boolean;
    scheduledDate: any;
    pushed_to_driver_at: any;
    shop_accepted_at: any;
    shop_created_at: any;
    dispatchedOrderLength: any;
    cityId: any;
    driverDeliveredAt: any;
    intervalId: any;
    viewDrivers:boolean ;
    skipShop:boolean = false;
    customerDetails:any = [];
    pusher = new Pusher('8d0ad959b5759a0aa3ca', {
        cluster: 'ap2'
    });

    constructor(private apiService: ApiService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private imageService: ImageService,
        private _lightboxConfig: LightboxConfig,
        private _lightbox: Lightbox,
        private router: Router
    )
    {
        _lightboxConfig.showZoom = true;
        _lightboxConfig.showDownloadButton = true;
    }

    open(type: String,index: number): void {
        // open lightbox
        if(type == "ORDER"){
            this._lightbox.open(this.orderImage, index);
        }else if(type == "DISPATCH"){
            this._lightbox.open(this.album, index);
        }else if(type == "ADDONS"){
            this._lightbox.open(this.addonImage, index);
        }else if(type == "DRIVER_IMAGE"){
            this._lightbox.open(this.driverUploadImage, index);
        }
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }

    ngOnInit() {
        this.getOrderDetails();
        this.getDispatchOrderByOrderId();
     //  this.getShopOrderByOrderId();
        this.imageURL = this.imageService.getMinImageurl();
        var channel = this.pusher.subscribe('blaack-forest');
        channel.bind('admin', (data) => {
            if(this.router.url == '/order-detail/'+this.orderId){
                // console.log('order-detail/'+this.orderId, data);
                this.getOrderDetails();
                this.getDispatchOrderByOrderId();
            }
        });
    }

    getAllShopDetailsByRegionId(){
        this.loading = true;
        this.apiService.getData('getAllShopDetailsByRegionId', this.regionId).subscribe((data) => {
            this.shopList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }
    displayStyle = "none";

    openPopup() {
        this.displayStyle = "block";
    }

    closePopup() {
        this.displayStyle = "none";
    }

    sanitize(url:string){
        return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    initiateRecording() {
        this.recording = true;
        let mediaConstraints = {
            video: false,
            audio: true
        };
        navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
        // console.log("success upendra=",this.successCallback.bind(this));
    }

    successCallback(stream: MediaStream) {
        var options = {
            mimeType: "audio/wav",
            numberOfAudioChannels: 1
        };
        //Start Actuall Recording
        var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
        this.record = new StereoAudioRecorder(stream, options);
        this.record.record();
    }

    stopRecording() {
        this.recording = false;
        this.record.stop(this.processRecording.bind(this));
        // console.log('upendra=',this.processRecording.bind(this));
    }

    processRecording(blob: Blob | MediaSource) {
        this.url = URL.createObjectURL(blob);
        // console.log('upendra=',this.url);
        this.str=this.url;
        // console.log('upendra output=',this.str);
    }

    errorCallback(error: any) {
        this.error = 'Can not play audio in your browser';
    }

    getVoice(){
        this.loading = true;
        this.apiService.getData('getVoicenotesss').subscribe((data) => {
            this.getvoice = data.data;
            //this.getvoice = atob(data.data);
            // console.log(this.getvoice);
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    store(){
        this.loading = true;
        //this.audiolaunch=btoa(this.url);
        const value = { voicenotes: this.url}
        //const value = { voicenotes: this.audiolaunch}
        this.apiService.postData(value, 'postVoicenotesss').subscribe((data) => {
            this.toastr.success(data.message);
            // console.log(data.message);
            this.loading = false;
        }, error => {
            this.loading = true;
        });
    }

    onChange(event){
        this.locationDetailsId = event;
    }

    getAllDriverList(){
        this.loading = true;
        // this.apiService.getData('getAllDriverListByLocationId', this.locationDetailsId).subscribe((data) => {
        this.apiService.getData('getAllDriverListByRegionId', this.cityId).subscribe((data) => {
            this.driverList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getAllLocationDetailsByRegionId(){
        this.loading = true;
        this.apiService.getData('getAllLocationDetailsByRegionId', this.regionId).subscribe((data) => {
            this.pincodeList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getDispatchOrderByOrderId(){
        this.loading = true;
        this.apiService.getData('getDispatchOrderByOrderId', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
        if(data.data.length > 0){
            this.getDispatchOrderId = data.data;
            this.dispatchOrderStatus = data.data[0].dispatch_order_status;
            if(this.getDispatchOrderId.length != 0){
                this.dispatch_order_id = data.data[0].dispatch_order_id;
                this.getDispatchImagesById();
            }else{
                this.getAllDriverList();
            }
            this.loading = false;
        }else{
            this.getAllDriverList();
        }
        }, error => {
            this.loading = false;
        });
    }

    getDriverOrderByOrderId(){
        this.loading = true;
        this.apiService.getData('getDriverOrderByOrderId', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
            this.getDriverOrderId = data.data;
            if(this.getDriverOrderId.length != 0){
            this.driver_order_id = data.data[0].dispatch_order_id;
            this.push_to_driver_id=data.data[0].driver_id;
            this.driver_acceptancee = data.data[0].driver_acceptance;
            this.driver_accepted_at = data.data[0].accepted_at;
            this.picked_up = data.data[0].picked_up;
            this.picked_up_at = data.data[0].picked_up_at;
            this.pushed_to_driver_at = data.data[0].created_at;
            this.driverDeliveredAt = data.data[0].delivered_at;
            // this.getDispatchImagesById();
            if(data.data[0].driver_id != 0){
                this.push_to_driver_success="driver Push success";
            }
            const album = {
                src: this.getDriverOrderId[0].driver_uploaded_image,
                caption: 'Driver Uploaded Image',
                thumb: this.getDriverOrderId[0].driver_uploaded_image
            };
            this.driverUploadImage.push(album)
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getShopOrderByOrderId(){
        this.loading = true;
        this.apiService.getData('getShopOrderByOrderId', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
            // console.log(data.data);
            this.getShopOrderId = data.data;
            if(this.getShopOrderId.length != 0){
            this.shop_accepted_at = data.data[0].accepted_at != null ? data.data[0].accepted_at : null;
            this.shop_created_at = data.data[0].created_at;
            this.picked_up_at = data.data[0].picked_up_at;
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getDispatchImagesById(){
        this.loading = true;
        this.apiService.getData('getDispatchImagesById', this.dispatch_order_id).subscribe((data) => {
        this.dispatchImagesList = data.data;
        this.dispatchImagesProductId = data.data[0].approve_status;
            if(this.dispatchImagesProductId!=0){
                this.admin_approved_at=data.data[0].admin_accepted_at;
            }
            this.dispatchImagesList.forEach(element => {
                const src = element.image;
                const caption = '';
                const thumb = element.image;
                const album = {
                    src: src,
                    caption: caption,
                    thumb: thumb
                };
                this.album.push(album)
            });
                this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    approveImage(dispatch_prepare_image_id="", dispatch_order_id=""){
        const value = {
            dispatch_prepare_image_id: dispatch_prepare_image_id,
            dispatch_order_id:dispatch_order_id,
            location_id:this.locationDetailsId
        };
        this.apiService.postData(value, 'approveImage').subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getDispatchImagesById();
                this.getOrderDetails();
            } else {
                this.toastr.warning(data.message);
                this.loading = false;
            }
        }, error => {
            this.loading = false;
        });
    }

    disApproveImage(dispatch_prepare_image_id="", dispatch_order_id="", rejectionComment) {
        if(rejectionComment !== "" || rejectionComment !== null  ){
            const value = {
            dispatch_prepare_image_id: dispatch_prepare_image_id,
            dispatch_order_id:dispatch_order_id,
            comments:rejectionComment,
            location_id:this.locationDetailsId
            };
        this.apiService.postData(value, 'disApproveImage').subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
            this.closePopup();
                this.getDispatchImagesById();
            } else {
                this.toastr.warning(data.message);
                this.loading = false;

            }
            }, error => {
            this.loading = false;
            });
        }else{
            this.toastr.warning("Enter Comments for Rejection");
        }
    }

    onPushDispatch(){
        this.loading = true;
        const param = {
            location_details_id: this.locationDetailsId,
            order_id:this.orderId,
            scheduled_date: this.scheduledDate
        }
        this.apiService.postData(param, 'createDispatchOrder').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                this.getDispatchOrderByOrderId();
                this.loading = false;
            } else {
                this.toastr.warning(data.message);
                this.loading = false;
            }
        }, error => {
            this.loading = false;
        });
    }

    onPushDriver(){
        this.loading = true;
        const param = {
            location_details_id: this.locationDetailsId,
            order_id:this.orderId,
            driver_id: this.driverId,
            city_id: this.cityId,
            shop_id: this.getShopOrderId[0].shop_id
        }
        this.apiService.postData(param, 'createDriverOrder').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                this.getDispatchOrderByOrderId();
                this.getDriverOrderByOrderId();
                this.loading = false;
            } else {
                this.toastr.warning(data.message);
                this.loading = false;
            }
        }, error => {
            this.loading = false;
        });
    }

    onPushToShop(){
        this.loading = true;
        const param = {
            order_id:this.orderId,
            shop_id: this.shopId,
            region_id: this.regionId
        }
        this.apiService.postData(param, 'createShopOrder').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
                this.getDispatchOrderByOrderId();
                if(this.deliveryMode == 'Door Step Delivery'){
                    this.getDriverOrderByOrderId();
                }else if(this.deliveryMode == 'Pick Up'){
                    this.getShopOrderByOrderId();
                }
                this.loading = false;
            } else {
                this.toastr.warning(data.message);
                this.loading = false;
            }
        }, error => {
            this.loading = false;
        });
    }

    getOrderDetails() {
        this.loading = true;
        this.apiService.getData('getOrderDetails', this.route.snapshot.paramMap.get('id')).subscribe((data) => {
        this.orderDetails = data.data;
        this.customerDetails = data.data.customer_details;
        this.addonDetails = data.data.ordered_addons;
        this.cityName = data.data.region.city.name;
        this.cityId = data.data.region.city_id;
        this.regionId = data.data.region_id;
        this.orderId = data.data.order_id;
        this.orderStatus= data.data.order_status;
        this.orderStatusName =  this.orderStatus;
        this.deliveryMode = data.data.delivery_mode;
        this.orderDeliveredAt= data.data.order_delivered_at;
        this.orderDetails.ordered_products.forEach(element => {
            const src = element.product_details[0].images[0].product_image;
            const caption = '';
            const thumb = element.product_details[0].images[0].product_image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this.orderImage.push(album)
        });
        this.addonDetails.forEach(element => {
            const src = element.image;
            const caption = '';
            const thumb = element.image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this.addonImage.push(album)
        });
        if(data.data.dispatch_orders){
            this.cakeDoneImages = data.data.dispatch_orders.cake_done_image;
            this.onlineTeamAccept = data.data.dispatch_orders.online_team_accept;
            this.locationDetailsId = data.data.dispatch_orders.location_details_id;
            this.dispatchAcceptancee = data.data.dispatch_orders.dispatch_order_status;
            this.dispatchAcceptedAt=data.data.dispatch_orders.dispatch_accepted_at;
            this.adminAcceptancee = data.data.dispatch_orders.online_team_accept;
        }
        if(this.deliveryMode == 'Door Step Delivery'){
            this.getAllDriverList();
        }
        this.getDriverOrderByOrderId();
        this.getShopOrderByOrderId();
        this.getAllShopDetailsByRegionId();
        this.pushToDispatch = data.data.push_to_dispatch;
        //this.pushToDispatch = data.push_to_dispatch;
        this.dispatchAcceptance=data.data.dispatchAcceptance;
        // console.log('dispatchAcceptance=',this.dispatchAcceptance);
        if (this.orderDetails.order_tracking_id) {
            this.trackId = this.orderDetails.order_tracking_id;
        }
        this.getAllLocationDetailsByRegionId();
        this.getAllDriverList();
        this.loading = false;
        // setTimeout(()=>{
        //   this.fetchOrdersDetailsRegressively();
        // }, 20000);
        }, error => {
        this.loading = false;
        });
    }

    goBack() {
        window.history.back();
    }

    onChangeStatus(event){
        this.orderStatusName = event;
    }

    onChangeDriver(event){
        this.driverId = event;
    }
    onChangeShop(event){
        this.shopId = event;
    }

    orderTrackingUpdate(id) {
        this.apiService.getData('orderTrackingUpdate', `${id}/${this.trackId}`).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
            } else {
                this.toastr.error(data.message);
            }
        });
    }
    openCommentPopUp(comments="") {
        this.viewComment = true;
        this.comment = comments;
    }

    closeCommentPopUp() {
        this.viewComment = false;
    }

    openShedulePopup() {
        this.viewSchedule = true;
    }
    closeShedulePopup() {
        this.viewSchedule = false;
    }

    updateStatus(){
        if(this.orderStatus == 'Confirmed'){
            if(this.orderStatusName == 'ReadyToDelivered'){
                this.showWarningPopUp('ReadyToDelivered');
            }else{
                this.orderStatusUpdate();
            }
        }else{
            this.orderStatusUpdate();
        }
    }

    orderStatusUpdate(){
        this.loading = true;
        this.apiService.getData('orderStatusUpdate', `${this.orderId}/${this.orderStatusName}`).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.getOrderDetails();
            } else {
                this.toastr.error(data.message);
            }
            this.loading = false;
        });
    }

    showWarningPopUp(status:string) {
        Swal.fire({
            title: 'Confirmation',
            text: "Are you sure to proceed to 'READY TO DELIVERY' without moving to 'DISPATCH'?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
            this.orderStatusUpdate();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                );
                this.orderStatusName = this.orderStatus;
            }
        });
    }
    fetchOrdersDetailsRegressively(){
        this.getOrderDetails();
    }

    ngOnDestroy() {
        this.clearInterval();
    }

    clearInterval(){
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
}
