import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import Pusher from 'pusher-js';
import { Howl } from 'howler';
import { ToastrService } from 'ngx-toastr';




@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    role: any = `${localStorage.getItem('role_name')}`;
    roleId: any = `${localStorage.getItem('role_id')}`;
    userName = `${localStorage.getItem('user_name')}`;
    sound = new Howl({
      src: ['../../../assets/sound/goes-without-saying-608.mp3']
    });

    pusher = new Pusher('8d0ad959b5759a0aa3ca', {
      cluster: 'ap2'
    });

    constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService) {

    }


    ngOnInit() {

      var channel = this.pusher.subscribe('blaack-forest');
      channel.bind('admin', (data) => {
        // console.log('header: ',data)
        this.showNotification(data);
        // this.checkNotificationPermission(data);
      });
    }



    logout() {
        this.router.navigate(['']);
        // this.apiService.getData('userLogout').subscribe((data) => {
            localStorage.clear();
            location.reload();
        // });
    }
    showNotification(data){
      this.sound.play();

      const notification=new Notification(data.title, {
          body: data.message,
          icon: '../../../assets/images/bf-logo-square.png',
          image:data.imageUrl
      });
    }

    checkNotificationPermission(data){
        if(Notification.permission ==="granted"){
            this.showNotification(data);

        }else if(Notification.permission!=="denied"){
           Notification.requestPermission().then(permission=>{
                // if(permission ==="granted"){
                //    this.showNotification(data);
                // }
                this.toastr.warning('Notification Permission Denied!');
            });
      }
    }


}
