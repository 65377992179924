import { Component, OnInit } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ImageService } from 'src/app/services/image.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from "@angular/forms";
@Component({
    selector: 'app-product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.css']
})
export class ProductImageComponent implements OnInit {
    productId: any = [];
    imageChangedEvent: any = '';
    croppedImage: any = '';
    currentImage: any = '';
    hoverClass = -1;
    exportImagesList: any = [];
    imageName = '';
    loading: boolean = false;
    allImageList: any = [];
    public files: NgxFileDropEntry[] = [];
    defaultImage = 'assets/images/imageload.gif';
    imageUrl: any = '';
    existImages = false;
    p = 1;
    loadingBtn: boolean;
    selectedFile: File;
    selectedFileUrl: string | ArrayBuffer;

    filePath: string;
    myForm: FormGroup;
    selectedFileName: any = "Choose image";
    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private imageService: ImageService,
        private imageCompress: NgxImageCompressService,
        private router: Router,
        public fb: FormBuilder
        ) {
          this.myForm = this.fb.group({
            img: [null],
           })
         }

    ngOnInit() {
        this.listAllProducts();
        this.imageUrl = this.imageService.getMinImageurl();
    }

    listAllProducts() {
        this.loading = true;
        this.apiService.getData('productDetails', this.route.snapshot.paramMap.get('id')).subscribe(data => {
            this.productId = data.data[0].id;
            this.allImageList = data.data[0].images;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }


    editImages() {
        this.existImages = true;
    }

    returnImage() {
      this.existImages = false;
    }

    imageUpdateProduct(imageFile) {
      this.loading = true
        const formData: FormData = new FormData();
        formData.append('product_id', this.productId);
        formData.append('product_image', imageFile);

        this.apiService.postData(formData, 'imageUpdateProduct').subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.listAllProducts();
                this.resetForm();
            } else {
                this.toastr.error(data.message);
            }
            this.loading = false
            this.loadingBtn = false;
        }, error => {
          this.loading = false
          this.loadingBtn = false;
        });
    }

    resetForm(){
      this.filePath = null;
      this.selectedFileName = null;
      this.myForm.reset();
    }
    deleteImageProduct(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.apiService.getData('deleteImageProduct', id).subscribe(data => {
            if (data.error === false) {
              this.toastr.success(data.message);
              this.p = 1;
            } else {
              this.toastr.error(data.message);
            }
            this.listAllProducts();
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      });
    }

    imagePreview(e) {
      const file = (e.target as HTMLInputElement).files[0];

      this.myForm.patchValue({
        img: file
      });

      this.myForm.get('img').updateValueAndValidity()
      this.selectedFile = e.target.files[0];
      this.selectedFileName = e.target.files[0].name;
      const reader = new FileReader();
      reader.onload = () => {
        this.filePath = reader.result as string;
      }
      reader.readAsDataURL(file)
      e.target.value = null;
    }

    submit() {
      this.imageUpdateProduct(this.selectedFile);
    }
}
