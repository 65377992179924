import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatSliderChange } from '@angular/material/slider';
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-delivered-orders',
  templateUrl: './delivered-orders.component.html',
  styleUrls: ['./delivered-orders.component.css']
})
export class DeliveredOrdersComponent implements OnInit {
    searchedKeyword:string;
    loading: boolean;
    searchField: FormControl = new FormControl();
    orderDetails: any = [];
    currentStatus = '';
    sliderClass = -1;
    p = 1;
    reason:String;
    orderDetailsforFilter: any = [];
    selectedFilter:any = "All";
    filterBtn:any = [
        'All',
        'Submitted',
        'Confirmed',
        'Dispatched',
        'ReadyToDelivered',
        'Delivered'
      ]
    showPopUp: boolean;
    popUpOrderId: any;
    type: string;
    constructor(private apiService: ApiService, private toastr: ToastrService) { }

    ngOnInit() {
       
       // this.listAllOrders();
        this.listAllDeliveredOrders();
        this.searchOrder();
        
    }

    filterTable(value){
        if(value == 'All'){
            this.orderDetails = this.orderDetailsforFilter;
        }else if(value == "Submitted"){
            this.orderDetails = this.orderDetailsforFilter.filter(orders => orders.order_status == "Submitted");
        }else if(value == 'Confirmed'){
            this.orderDetails = this.orderDetailsforFilter.filter(orders => orders.order_status == "Confirmed");
        }else if(value == 'Dispatched'){
            this.orderDetails = this.orderDetailsforFilter.filter(orders => orders.order_status == "Dispatched");
        }else if(value == 'ReadyToDelivered'){
            this.orderDetails = this.orderDetailsforFilter.filter(orders => orders.order_status == "ReadyToDelivered");
        }else if(value == 'Delivered'){
            this.orderDetails = this.orderDetailsforFilter.filter(orders => orders.order_status == "Delivered");
        }else if(value == 'Pick Up'){
            this.orderDetails = this.orderDetailsforFilter.filter(orders => orders.delivery_mode == "Pick Up");
        }else if(value == 'Door Step Delivery'){
            this.orderDetails = this.orderDetailsforFilter.filter(orders => orders.delivery_mode == "Door Step Delivery");
        }

    }
    listAllDeliveredOrders() {
        this.loading = true;
        this.apiService.getData('listAllDeliveredOrders').subscribe((data) => {
            this.orderDetails = data.data;
            this.orderDetailsforFilter = data.data;
            this.loading = false;
            }, error => {
            this.loading = false;
        });
    }
    cancelOrder(id, reason = null) {
        if (!reason) {
            this.toastr.warning('Please Enter the Reason');
           return false;
       }
        this.closePopUp();
        
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you Sure to cancel this Order #'+id+ " ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                this.apiService.getData('cancelOrder', `${id}/${reason}`).subscribe((data) => {
                    if (data.error === false) {
                        this.listAllDeliveredOrders();
                        this.toastr.success(data.message);
                        this.sliderClass = -1;
                        Swal.fire(
                            "Order Cancel",
                            'Order Cancelled Successfully.',
                            'success'
                        );
                    } else {
                        this.toastr.error(data.message);
                    }
                    });
               
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your Order is safe :)',
                    'error'
                );
            }
        });

        
        
    }

    orderStatusUpdate(id) {
        if(this.currentStatus === 'Submitted' || this.currentStatus == '') {
            this.toastr.warning('Status cant be updated to Submitted');
            this.sliderClass = -1;
            return false;
        }
        this.apiService.getData('orderStatusUpdate', `${id}/${this.currentStatus}`).subscribe((data) => {
        if (data.error === false) {
            this.listAllDeliveredOrders();
            this.toastr.success(data.message);
            this.sliderClass = -1;
        } else {
            this.toastr.error(data.message);
        }
        });
    }

    formatLabel(value: number) {
        if (value === 4) {
            return 'Delivered';
        } else if (value === 3) {
            return 'Shipped';
        } else if (value === 2) {
            return 'Processed';
        } else if (value === 1) {
            return 'Submitted';
        }
        return value;
    }

    onInputChange(event: MatSliderChange) {
        if (event.value === 4) {
            this.currentStatus = 'Delivered';
        } else if (event.value === 3) {
            this.currentStatus = 'Shipped';
        } else if (event.value === 2) {
            this.currentStatus = 'Processed';
        } else if (event.value === 1) {
            this.currentStatus = 'Submitted';
        }
    }

    orderUpdate(index) {
        this.sliderClass = index;
    }

    searchOrder() {
        this.searchField.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged(), switchMap((query) =>
            this.apiService.searchData('searchOrder', query)
        ))
        .subscribe((result) => {
            if (this.searchField.value === '') {
                this.listAllDeliveredOrders();
                return false;
            }
            if (result.data.length === 0) {
                this.orderDetails = '';
            } else {
                this.p = 1;
                this.orderDetails = result.data;
            }
        });
    }

    checkLabelStatus(status) {
        if (status === 'Delivered') {
            return 4;
        } else if (status === 'Shipped') {
            return 3;
        } else if (status === 'Processed') {
            return 2;
        } else if (status === 'Submitted') {
            return 1;
        }
    }
    // holdOrUnHold(orderId, status){
    //         this.apiService.getData('orderStatusUpdate', `${orderId}/${status}`).subscribe((data) => {
    //         if (data.error === false) {
    //             this.listAllDeliveredOrders();
    //             this.toastr.success(data.message);
    //         } else {
    //             this.toastr.error(data.message);
    //         }
    //     });
    // }

    openPopUp(orderId: any, type: string) {
        this.reason = "";
         this.popUpOrderId = orderId;
         this.type = type;
         this.showPopUp = true;
    }
    closePopUp() {
      this.showPopUp = false;
    }
    holdOrUnHold(orderId, status, reason=null){
        if(this.type == ("CANCEL" || "HOLD")){
             if (!reason) {
                 this.toastr.warning('Please Enter the Reason');
                return false;
            }
        }
        this.closePopUp();

        Swal.fire({
            title: 'Confirmation',
            text: 'Are you Sure to '+status+' this Order #'+orderId+ " ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                this.apiService.getData('orderStatusUpdate', `${orderId}/${status}/${reason}`).subscribe((data) => {
                    if (data.error === false) {
                        this.listAllDeliveredOrders();
                        // this.toastr.success(data.message);
                        Swal.fire(
                            status,
                            status+' Successfull.',
                            'success'
                        );
                    } else {
                        this.toastr.error(data.message);
                    }
                });

               
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your Order is safe :)',
                    'error'
                );
            }
        });
        }
    

}

