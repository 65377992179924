import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {
  productList: any = [];
  loading = false;
  loadingBtn = false;
  showPopup = false;
  stockBox = false;
  modalBoxName = '';
  keyword = 'name';
  categoryName: any;
  productName: any;
  productPrice: any;
  productDiscPrice: any;
  productDesc: any;
  productSize = '';
  productImages: any = [];
  editProductId: any;
  shortDescription;

  selectedSize: any = [];
  selectedFlavour:any = [];
  sizeSettings = {};
  flavourSettings = {};
  basePrice;
  categories: any = [];
  sno = 1;
  searchField: FormControl = new FormControl();
  errorMsg: any = [];
  viewBox = false;
  showImage = true;
  imageUrl = '';

  productStocks:any = [];
  stockProductName = '';
  stockForm: FormGroup;



  // sizeQuantity:any[] = [];

  defaultImage = 'assets/images/loader.gif';

  @ViewChild('auto', { static: false }) auto;
  @ViewChild('auto', { static: false, read: ElementRef }) dishAuto: ElementRef;
  @ViewChild('sizeDropDown', { static: false }) sizeDropDown: AngularMultiSelect;
  @ViewChild('flavourDropDown', { static: false }) flavourDropDown: AngularMultiSelect;
  taxList: any = [];
  taxId: any = 0;
  newProduct: any = 1;
  bestSelling: any = 1;
  regionList: any = [];
  regionArray: any = [];
  regionId: any = [];

  flavourList: any = [];
  flavourArray: any = [];
  flavourId: any = [];
  description;
  variation:any = [];
  weightList: any = [];
  clickValue: any = 1;
  weightLength: any;
  startTime:any;
  endTime:any;
  hsn:any;
  preparation:any;
  unitList: any = [];
  unitId: any = 1;
  subCategories:any = [];
  filteredSubCategories:any=[];
  productId=this.route.snapshot.paramMap.get('id');
  categoryId: any;
  subCategoryName: any;
  filteredHsnCodeList: any;
  activeCategories: any;
  COD_egg: boolean = false;
  COD_eggless: boolean= false;
  baseEggPrice: any;
  baseEgglessPrice: any;
  selectedUnit: any;
  subCategory: any;
  eggPreparation:number = 0;
  egglessPreparation:number = 0;
  constructor(private apiService: ApiService, private toastr: ToastrService, private imageService: ImageService, private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    ) { }


  ngOnInit() {


    this.sizeSettings = {
      singleSelection: false,
      text: 'Select Region',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
  };



  this.flavourSettings = {
    singleSelection: false,
    text: 'Select Flavour',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class'
  };

    this.getActiveCategory();
    this.getActiveSubCategory();
    this.listAllTax();
    this.listAllRegions();
    this.listAllFlavour();
    this.listAllWeight();
    this.listAllUnits();


  }
  getActiveSubCategory() {
    this.categories = [];
    this.loading = true;
    this.apiService.getData('getActiveSubCategory').subscribe(data => {
      this.subCategories = data.data;

    });
  }

  listAllWeight(){
    this.loading = true;
    this.apiService.getData('getAllActiveWeightList').subscribe((data) => {
        this.weightList = data.data;
        this.weightLength = this.weightList.length;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }

   listAllUnits(){
    this.loading = true;
    this.apiService.getData('getAllActiveUnitList').subscribe((data) => {
        this.unitList = data.data;
        this.show(this.productId);

        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }


   removevalue(i) {

     this.variation.splice(i, 1);
   }

   addvalue() {
     this.clickValue = this.clickValue + 1;
    this.variation.push({id:'', weight: '', egg: '', eggLess:'',  eggPreparation: 0, egglessPreparation:0, sku:''});


   }


  listAllRegions(){
    this.loading = true;
    this.apiService.getData('getAllActiveRegionList').subscribe((data) => {
        const regions = data.data;
        for (const region of regions) {
          this.regionList.push({ id: region.region_id, itemName: region.city.name });
      }
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }

   getActiveCategory() {
    this.categories = [];
    this.loading = true;
    this.apiService.getData('getActiveCategory').subscribe(data => {
        const categories = data.data;
        this.activeCategories = data.data;
        // for (const category of categories) {
        //     this.categories.push({ id: category.id, name: category.sub_category_name, category_id:category.category_id});
        // }
    });
  }

  listAllFlavour(){
    this.loading = true;
    this.apiService.getData('getAllActiveFlavourList').subscribe((data) => {
        //this.flavourList = data.data;

        const flavours = data.data;
        for (const flavour of flavours) {
          this.flavourList.push({ id: flavour.flavour_id, itemName: flavour.flavour_name });
      }
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }


  listAllTax(){
    this.loading = true;
    this.apiService.getData('getAllActiveTaxList').subscribe((data) => {
        this.taxList = data.data;
        this.loading = false;
    }, error => {
        this.loading = false;
    });
   }


   getFilteredSubCategory(id){
    if(id != 0){
      this.filteredSubCategories = [];
    const subCategories = this.subCategories.filter(p => p.category_id===id)
     for (const subCategory of subCategories) {
            this.filteredSubCategories.push({ id: subCategory.id, name: subCategory.sub_category_name, category_id:subCategory.category_id});
        }
    }
    // this.subCategoryName = sub;
  }
  getHsnTax(event){
    this.subCategoryName = event;
    if(this.subCategoryName.id != 0){
      let hsnAndTaxFiltered = this.subCategories.filter(p => p.id===this.subCategoryName.id);

        for (const hsnFilter of hsnAndTaxFiltered) {
              this.filteredHsnCodeList.push({ hsn_code: hsnFilter.hsn});
        }
        for(const tax of hsnAndTaxFiltered){
          this.taxList = this.taxList.filter(t => t.tax_id===tax.tax_id);
        }
        }
  }
  setVariationPrice(i, weight, event, subCatName){
    let subCategoryIndex = this.filteredSubCategories.findIndex((e)=> e.id == subCatName);
    let subCategoryName = this.filteredSubCategories[subCategoryIndex].name;
     if(this.selectedUnit == "Kgs"){
     let selectedWeightIndex = event.target["selectedIndex"] - 1;
      //if(selectedWeightIndex != -1){
        let selectedWeight = this.weightList.find(e => e.weight_id === weight);
        selectedWeight = selectedWeight.weight_name;
        let egg = +this.baseEggPrice * +selectedWeight * 2;
        let eggless = +this.baseEgglessPrice* +selectedWeight * 2;
        this.variation[i].egg = egg;
        this.variation[i].eggLess  = eggless;
        this.variation[i].sku =  this.getSKU(selectedWeight, subCategoryName);
      //}
    }else{
      console.log("Automation works only for Kgs");
    }

  }
  getSKU(selectedWeight, subCategoryName){
    //SKU Constaints
    let subCategoryShortName = subCategoryName.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase();
    var productShortName = this.productName.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase();
    selectedWeight = selectedWeight.length == 1 ? selectedWeight+".0" : selectedWeight;
    var SKU = subCategoryShortName+"_"+productShortName+"_"+selectedWeight;
    return SKU;
 }
  show(id) {
    this.apiService.show('product/' + id).subscribe((data) => {
        const value = data.data;
        this.editProductId = id;
        this.categoryName = value.category.id;
        this.categoryId = value.category_id;
        this.flavourArray = value.flavour;
        this.productName = value.product_name;
        this.productPrice = value.product_price;
        this.productDiscPrice = value.product_discount_price;
        this.description = value.product_description;
        this.shortDescription = value.short_description;
        this.productImages = value.images;
        this.hsn = value.hsn;
        this.taxId = value.tax_id;
        this.startTime = value.start_time;
        this.endTime = value.end_time;
        this.newProduct = value.new_product;
        this.bestSelling = value.best_selling;
        this.unitId = value.unit_id;
        this.preparation = value.preparation;
        this.baseEggPrice = value.product_price;
        this.baseEgglessPrice = value.base_eggless_price;
        this.COD_egg = value.COD_egg == 1 ? true : false;
        this.COD_eggless = value.COD_eggless == 1 ? true : false;
        this.subCategoryName = value.sub_category_id;
        this.subCategory = value.sub_category;
        // this.subCategoryName.push({ id: subCategoryName.id, name: subCategoryName.sub_category_name, category_id:subCategoryName.category_id});

        this.getFilteredSubCategory(this.categoryName);


        let regionArray = value.region;
        regionArray.forEach(element => {

          this.regionArray.push({ id: element.itemName.region_id, itemName: element.itemName.city.name});
        });

          this.onChangeUnit(this.unitId)

        value.variation.forEach(element => {
          this.variation.push({ id: element.variation_id, weight: element.weight_id, egg: element.egg, eggLess:element.eggLess,eggPreparation: element.egg_preparation, egglessPreparation:element.eggless_preparation, sku: element.sku});
        });
          this.clickValue = this.variation.length;
    });
}
checkEggPreparationHours(){
  let res = false;
  for(let item of this.variation){
    if(item.egg != 0 && item.eggPreparation == 0){
      res = true;
      break;
    }
  }
  return res;
}
checkEgglessPreparationHours(){
  let res = false;
  for(let item of this.variation){
     if(item.eggLess != 0 && item.egglessPreparation == 0){
      res = true;
      break;
    }
  }
  return res;
}

update(id) {

  if(this.categoryName == 0 ){
    this.toastr.warning('Please Select Category Name ');
    return false;
  }else if(this.subCategoryName == null){
    this.toastr.warning('Please Select Sub Category Name  ');
    return false;
  }else if(this.productName == null || this.productName == "" ){
    this.toastr.warning('Please enter product name');
    return false;
  }else if(this.unitId == 0){
    this.toastr.warning('Please enter unit');
    return false;
  }else if(this.description == "" || this.description == null ){
    this.toastr.warning('Please enter description');
    return false;
  }else if(this.shortDescription == "" || this.shortDescription == null ){
    this.toastr.warning('Please enter short Description');
    return false;
  }else if(this.hsn == 0){
    this.toastr.warning('Please enter hsn');
    return false;
  }else if(this.taxId == 0){
    this.toastr.warning('Please enter taxId');
    return false;
  }else if(this.regionArray == 0){
    this.toastr.warning('Please Enter Region');
    return false;
  }else if(this.flavourArray == 0){
    this.toastr.warning('Please Enter Flavour');
    return false;
  }else if(this.newProduct == null){
    this.toastr.warning('Please Enter new Product');
    return false;
  }else if(this.bestSelling == null){
    this.toastr.warning('Please Enter best Selling');
    return false;
  }else if(this.checkEggPreparationHours()){
    this.toastr.warning('Please Select Preparation Hours For Egg');
    return false;
  }else if(this.checkEgglessPreparationHours()){
    this.toastr.warning('Please Select Preparation Hours For Eggless');
    return false;
  }else{
    this.loadingBtn = true;
    this.regionId = [];
    console.log(this.regionArray);
    this.regionArray.forEach(element => {
    this.regionId.push(element.id);
    });

    this.selectedSize= this.regionId.toString();

    this.flavourId = [];
    this.flavourArray.forEach(element => {
        this.flavourId.push(element.id);
    });
    this.selectedFlavour = this.flavourId.toString();
    this.apiCall('update', 'product/' + id);
  }
}


  apiCall(name, url, value: any = '') {
    value = {
        product_id : this.productId,
        category_id : this.categoryName,
        sub_category_id:this.subCategoryName,
        product_name: this.productName,
        product_description: this.description,
        hsn:this.hsn,
        tax_id:this.taxId,
        preparation:this.preparation,
        new_product:this.newProduct,
        best_selling:this.bestSelling,
        unit_id:this.unitId,
        short_description:this.shortDescription,
        product_price:this.baseEggPrice,
        base_eggless_price:this.baseEgglessPrice,
        COD_egg : this.COD_egg == true ? 1 : 0,
        COD_eggless : this.COD_eggless == true ? 1 : 0,
    }

    if(this.selectedSize.length) {
        value['region'] = this.selectedSize;
    }

    if(this.selectedFlavour.length){
      value['flavour'] = this.selectedFlavour;
    }
    if(this.variation.length){
      value['variation'] = this.variation;
    }

    this.apiService[name](url, value).subscribe((data) => {
        if (data.error === false) {
            this.toastr.success(data.message);
            this.loadingBtn = false;

            this.router.navigate(['products']);

        } else {
            this.toastr.error(data.message);
            this.loadingBtn = false;
            this.errorMsg = data.data;
        }
    });
  }

  onChangeTax(event){
    this.taxId = event;
  }
  onChangeUnit(event){
    this.unitId = event;

    this.selectedUnit = (this.unitList.filter(t => t.unit_id===this.unitId));
    this.selectedUnit =  this.selectedUnit[0].unit_name;


    this.apiService.getData('getAllActiveWeightListByUnitId', this.unitId).subscribe((data) => {
      this.weightList = data.data;
      this.weightLength = this.weightList.length;

      this.loading = false;
  }, error => {
      this.loading = false;
  });

  }
  onChangeNew(event){
    this.newProduct = event;
  }

  onChangeSelling(event){
    this.bestSelling = event;
  }

  onDeSelectAllSizes(event) {
    this.selectedSize = [];
    this.regionArray = [];
    this.regionId = [];
  }

  openSize(event) {
    this.sizeDropDown.openDropdown();
  }

  onDeSelectAllFlavour(event) {
    this.selectedFlavour = [];
    this.regionArray = [];
    this.regionId = [];
  }

  openFlavour(event) {
    this.flavourDropDown.openDropdown();
  }

  onFocused(e) {
    this.auto.close();
  }
  onChangeBest(event){
    this.bestSelling = event;
  }

}
